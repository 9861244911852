<div class="progress-loader" [hidden]="!loading">
  <div
    class="progress-loader1 d-flex justify-content-center align-items-center"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="center center"
    [hidden]="!loading"
  >
    <div class="loader">Loading...</div>
  </div>
</div>
