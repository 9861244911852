import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
   
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link:'/dashboard',
        
    },
    {
        id: 2,
        label: 'Staff',
        icon: 'bxs-user-detail',
        link: '/users/list',
        
    },
    // {
    //     id: 13,
    //     label: 'ApproveStaff',
    //     icon: 'bxs-user-check',
    //     link: '/users/approve',
        
    // },
    {
        id: 3,
        label: 'Patient',
        icon: 'bx bx-user-circle',
        link: '/patients/list',
        
    },
    
    // {
    //     id: 3,
    //     label: 'Patient',
    //     icon: 'bx bx-user-circle',
    //     subItems: [
    //         {
    //             id: 21,
    //             label: 'List',
    //             link: '/patients/list',               
    //             parentId: 3
    //         },
    //         {
    //             id: 22,
    //             label: 'Documents',
    //             link: '/patients/documents',
    //             parentId: 3
    //         },
    //     ]
    // },
    {
        id: 4,
        label: 'Appointments',
        icon: 'bx-calendar',
        link: '/appointments/list',
       
    },
    // {
    //     id: 1001,
    //     label: 'HomeService',
    //     icon: 'bx bx-customize',
    //     subItems: [
    //         {
    //             id: 1101,
    //             label: 'MainCategory',
    //             link: '/department/list',
    //             parentId: 1001,
    //         },
    //         {
    //             id: 1201,
    //             label: 'SubCategory',
    //             link: '/tests/list',
    //             parentId: 1001,
    //             subItems: [
    //                 {
    //                     id: 1211,
    //                     label: 'Packages',
    //                     link: '/categories/packages',
    //                     parentId: 1201
    //                 },
    //                 {
    //                     id: 1212,
    //                     label: 'LabTest',
    //                     link: '/categories/labtest',
    //                     parentId: 1201
    //                 },
    //                 {
    //                     id: 1213,
    //                     label: 'Others',
    //                     link: '/categories/others',
    //                     parentId: 1201
    //                 },
    //             ]
    //         },
	// 		{
    //             id: 1301,
    //             label: 'Services',
    //             link: '/others/list',
    //             parentId: 1001,
    //             subItems: [
    //                 {
    //                     id: 1311,
    //                     label: 'LabPackage',
    //                     link: '/labpackage/list',
    //                     parentId: 1201
    //                 },
    //                 {
    //                     id: 1312,
    //                     label: 'Tests',
    //                     link: '/tests/list',
    //                     parentId: 1201
    //                 },
    //                 {
    //                     id: 1313,
    //                     label: 'Others',
    //                     link: '/others/list',
    //                     parentId: 1201
    //                 },
    //             ]
    //         },
    //     ]
    // },
    // {
    //     id: 5,
    //     label: 'Bills',
    //     icon: 'bx-receipt',
    //     link: '/bills/list',
	// 	 badge: {
    //         variant: 'info',
    //         text: 'MENUITEMS.DASHBOARDS.BADGE',
    //     },
        
    // },
	// {
    //     id: 6,
    //     label: 'Services',
    //     icon: 'bx bx-customize',
    //     subItems: [
    //         {
    //             id: 23,
    //             label: 'Lab Package',
    //             link: '/labpackage/list',
    //             parentId: 6
    //         },
    //         {
    //             id: 24,
    //             label: 'Tests',
    //             link: '/tests/list',
    //             parentId: 6
    //         },
	// 		{
    //             id: 25,
    //             label: 'Others',
    //             link: '/others/list',
    //             parentId: 6
    //         },
			
            
    //     ]
    // },
    
   
	
    
    // {
    //     id: 7,
    //     label: 'Categories',
    //     icon: 'bx bx-dialpad-alt',
    //     subItems: [
    //         {
    //             id: 26,
    //             label: 'Packages',
    //             link: '/categories/packages',
    //             parentId: 7
    //         },
    //         {
    //             id: 27,
    //             label: 'Lab Test',
    //             link: '/categories/labtest',
    //             parentId: 7
    //         },
	// 		{
    //             id: 28,
    //             label: 'Others',
    //             link: '/categories/others',
    //             parentId: 7
    //         },
			
            
    //     ]
    // },
    // {
    //     id: 8,
    //     label: 'Department',
    //     icon: 'bx bx-grid-alt',
    //     link: '/department/list',
        
    // },
    {
        id: 5,
        label: 'Promotions',
        icon: 'bx bx-money',
        subItems: [
            {
                id: 31,
                label: 'Promotion',
                link: '/promotion/list',
                parentId: 5
            },
            {
                id: 32,
                label: 'PromotionHistory',
                link: '/promotion-history/list',
                parentId: 5
            },   
            // {
            //     id: 35,
            //     label: 'ApprovePromotion',                
            //     link: '/promotion/approve',
            //     parentId:11
                
            // },
        ]
    },
    // {
    //     id: 8,
    //     label: 'Specialization',
    //     icon: 'bx bx-dna',
    //     subItems: [
    //         {
    //             id: 29,
    //             label: 'ServiceCategory',
    //             link: '/specialization/list',
    //             parentId: 8
    //         },
    //         {
    //             id: 30,
    //             label: 'ServiceList',
    //             link: '/specialization/service-list',
    //             parentId: 8
    //         }   
    //     ]
    // },
    // {
    //     id: 9,
    //     label: 'Settings',
    //     icon: 'bx bx-brightness',
    //     link: '/settings/add',
        
    // },
	// {
    //     id: 10,
    //     label: 'Clinics',
    //     icon: 'bx bx-test-tube',
    //     subItems: [
    //         {
    //             id: 33,
    //             label: 'Dashboard',
    //             link: '/labs/view',
    //             parentId: 10
    //         },
    //         {
    //             id: 34,
    //             label: 'ClinicsList',
    //             link: '/labs/list',
    //             parentId: 10
    //         }
			
            
    //     ]
    // },
    {
        id: 6,
        label: 'Reports',
        icon: 'bx bx-file',
        link: '/reports/list',
        
    },
    {
        id: 7,
        label: 'store',
        icon: 'bx bx-data',
        subItems: [
            {
                id: 1401,
                label: 'ProductCategory',
                link: '/store/category-list',
                parentId: 7
            }, 
            {
                id: 1402,
                label: 'Products',
                link: '/store/product-list',
                parentId: 7
            },  
        ]
    },
    {
        id: 8,
        label: 'Orders',
        icon: 'bx-cart-alt',
        link: '/orders/list',
       
    },
    {
        id: 9,
        label: 'VendorList',
        icon: 'bx-user-plus',
        link: '/vendor/list',
       
    },
    {
        id: 10,
        label: 'InstantConsultation',
        icon: 'bx-timer',
        link: '/instantconsultation/list',
       
    },
    {
        id: 11,
        label: 'MeetingRoom',
        icon: 'bx-video',
        link: '/meetingroom/list',
       
    },
    {
        id: 12,
        label: 'LifeStyle',
        icon: 'bx bx-rotate-right',
        subItems: [
            {
                id: 1401,
                label: 'Category',
                link: '/lifestyle/category',
                parentId: 12
            }, 
            {
                id: 1402,
                label: 'Articles',
                link: '/lifestyle/articles',
                parentId: 12
            },  
        ]
    },
    // {
    //     id: 11,
    //     label: 'Health Check',
    //     icon: 'bx bx-user-pin',
    //     subItems: [
    //         {
    //             id: 31,
    //             label: 'Employers',
    //             link: '/employeecheck/employers',               
    //             parentId: 11
    //         },
    //         {
    //             id: 32,
    //             label: 'Service Request',
    //             link: '/employeecheck/servicerequest',
    //             parentId: 11
    //         },
    //         {
    //             id: 33,
    //             label: 'Packages',
    //             link: '/employeecheck/list',
    //             parentId: 11
    //         },
           
    //     ]
    // },
	
    
    
];

export const CLINICMENU: MenuItem[] = [
   
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link:'/branch-dashboard',
    },
    {
        id: 2,
        label: 'Staff',
        icon: 'bxs-user-detail',
        link: '/branchusers/list',  
    },
    {
        id: 4,
        label: 'Appointments',
        icon: 'bx-calendar',
        link: '/appointments/list',
    },
	// {
    //     id: 6,
    //     label: 'Services',
    //     icon: 'bx bx-customize',
    //     subItems: [
    //         {
    //             id: 23,
    //             label: 'Lab Package',
    //             link: '/labpackage/list',
    //             parentId: 6
    //         },
    //         {
    //             id: 24,
    //             label: 'Tests',
    //             link: '/tests/list',
    //             parentId: 6
    //         },
	// 		{
    //             id: 25,
    //             label: 'Others',
    //             link: '/others/list',
    //             parentId: 6
    //         },
			
            
    //     ]
    // },
    {
        id: 8,
        label: 'Specialization',
        icon: 'bx bx-dna',
        subItems: [
            {
                id: 29,
                label: 'Service Category',
                link: '/specialization/list',
                parentId: 8
            },
            {
                id: 30,
                label: 'Service List',
                link: '/specialization/service-list',
                parentId: 8
            }   
        ]
        
    },
    {
        id: 11,
        label: 'Promotions',
        icon: 'bx bx-money',
        subItems: [
            {
                id: 31,
                label: 'Promotion',
                link: '/promotion/list',
                parentId: 11
            },
            // {
            //     id: 32,
            //     label: 'Promotion History',
            //     link: '/promotion-history/list',
            //     parentId: 11
            // }   
        ]
    },
    {
        id: 9,
        label: 'Settings',
        icon: 'bx bx-brightness',
        link: '/settings/add',
        
    }
];

