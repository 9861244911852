export class AppEnum {}

// export enum APIResponse {
//   Success = 0,
//   Failed = 1,
//   ServerError = 2,
//   RecordExistAlready = 3,
// }

export enum APIResponse {
  Success = 1000,
  Failed = 1001,
  ServerError = 1002,
  RecordExistAlready = 1003,
  MobileAlreadyExists = 1003,
  NotVerifiedByAdmin = 1004,
  passwordIncorrect =  1005,
  
}

export enum UserType {
  CareGiver = '2',
  Patient = '3',
  Vendor = '33',
  Promotion= '1',
}

export enum ReportType {
  CareGiver= '1',
  Orders = '2',
}

export enum AppointmentStatus {
  Approved = 1,
  Completed = 2,
  Cancelled = 3,
}
export enum AppointmentTriggerSource {
  AppointmentList = 1,
  ResultReadingList = 2,
}


export enum AppointmentStages {
  Scheduled = 1,
  Completed = 2,
  Cancelled = 3
  // Accpeted = 1,
  // OnTheWay = 2,
  // ArrivedDesination = 3,
  // ServiceInProgress = 4,
  // ServiceCompleted = 5,
  // SampleSubmittedToLab = 6,
  // ResultPublished = 7,
}

export enum AppointmentStageMessages {
  Scheduled = "Approved",
  Completed = "Completed",
  Cancelled = "Cancelled",
  // ArrivedDesination = "Arrived at patient location",
  // CollectionInProgress = "Service in progress",
  // CollectionCompleted = "Service completed",
  // SampleSubmittedToLab = "Sample submitted",
  // ResultPublished = "Result published",
}

export enum InClinicAppointmentStages {
  Scheduled = 0,
  Arrived = 1,
  InProgress = 2,
  ServiceCompleted = 3,
  AmountCollected = 4,
  ReportUploaded = 5,
}

export enum InClinicAppointmentStageMessages {
  Scheduled = "Approved",
  Arrived = "Arrived",
  InProgress = "In Progress",
  ServiceCompleted = "Service Completed",
  AmountCollected = "Amount Collected",
  ReportUploaded = "Report Published",
}

export enum InstantAppointmentStages {
  Scheduled = 1,
  Completed = 2,
  Cancelled = 3,
}
export enum ScheduledAppointmentStages {
  Scheduled = 1,
  Completed = 2,
  Cancelled = 3,
}
export enum ScheduledAppointmentStageMessages {
  Scheduled = "Approved",
  Completed = "Completed",
  Cancelled = "Cancelled",
}
export enum InstantAppointmentStageMessages {
  Scheduled = "Approved",
  Completed = "Completed",
  Cancelled = "Cancelled",
}
export enum PractiseUserRoles {
  LabTech = 1,
  Nurse = 2,
  Physiotherapist = 3,
  GeneralPhysician = 4,
}

export enum ServiceSectors {
  LabTech = 1,
  Nurse = 2,
  Physiotherapist = 3,
  GeneralPhysician = 4,
}

export enum AppointmentLogStatus {
  Approved = 1,
  Completed = 2,
  Cancelled = 3,
 
}
export enum AppointmentLogStatus1 {
  // Approved = 1,
  // Completed = 2,
  // Cancelled = 3,
  Open= 1,
  Scheduled= 2,
  Initiated= 3,
  Arrived= 4,
  SampleCollection= 5,
  SampleCollected= 6,
  SampleSubmitted= 7,
  ExaminationInProgress= 8,
  ResultSubmitted= 9,
}
export enum MaxFileSize {
  TWOMB = 0,
  FIVEMB = 1,
}

export enum FileUploadType {
  Sector = 1,
  Package = 2,
  CommonService = 3,
  AppointmentResult = 4,
  Patient = 5,
  Employee = 7,
  EmployeePackage = 9,
  EmployeeAppointmentResult = 10,
  Users = 5,
  PromoImage = 5,
  SpecializationCategoryImage = 13,
  SpecialityImage = 14,
  Staff = 15,
  Clinic = 6,
  CareGiverAttachment = 17,
  serviceProviderLicense=18,
}

export enum ImageUploadType {
  Category = 1,
  Product = 2,
  Vendor = 3,
}

export enum UserRoleAccess {
  SuperAdmin= 1,
  Clinic= 2,
  Employee= 3,
};

export enum AppointmentServiceType {
  Scheduled = 1,
  Instant = 3,
  // Instant = 3,
  // TeleMedicine = 1,
  // InClinic = 2,
  // HomeService = 3,
};
