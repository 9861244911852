import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/auth.models";
import { AppConstants } from "src/app/utils/app-constants";

@Injectable({ providedIn: "root" })
export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("currentUser")));
    this.currentUser = this.currentUserSubject.asObservable();

    // if (localStorage.getItem("currentUser") && localStorage.getItem("currentUser") !== 'undefined')  {

    //     }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject?.value;
  }

  // login(userName: string, password: string) {
  //   return this.http.post<any>(AppConstants.loginURL, { userName, password }).pipe(
  //     map((user) => {
  //       if (user && user.status == 0) {
  //         localStorage.setItem("currentUser", JSON.stringify(user));
  //         this.currentUserSubject.next(user);
  //       }
  //       return user;
  //     })
  //   );
  // }

  login(email: string, password: string) {
    let headers = new HttpHeaders({
      user:'admin' });
  let options = { headers: headers };
    return this.http.post<any>(AppConstants.loginURL, { email, password }, options).pipe(
      map((user) => {
        if (user && user.status.code == 1000) {
          let userdata = user?.data?.userDetails;
          localStorage.setItem("currentUser", JSON.stringify(userdata));
          this.currentUserSubject.next(userdata);
        }
        return user;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
}
