<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">{{ year }} © {{"Blueberry" | translate}}.</div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">{{"Allrightsreserved" | translate }}</div>
      </div>
    </div>
  </div>
</footer>
