import { environment } from "../../environments/environment";
export * from "./app-enum";
export * from "./app-literals";

export class AppConstants {
  public static get loginURL(): string {
    return `${environment.domainName}${environment.loginURL}`;
  }

  public static get getProviderAppointmentListURL(): string {
    return `${environment.domainName}${environment.getProviderAppointmentListURL}`;
  }

  public static get getLabAppointmentListURL(): string {
    return `${environment.domainName}${environment.getLabAppointmentListURL}`;
  }

  public static get getPatientListURL(): string {
    return `${environment.domainName}${environment.getPatientListURL}`;
  }
  public static get getSPPatientsListURL(): string {
    return `${environment.domainName}${environment.getSPPatientsListURL}`;
  }
  public static get getAvailableTimeListURL(): string {
    return `${environment.domainName}${environment.getAvailableTimeListURL}`;
  }
  public static get getDateWiseAvailabilityURL(): string {
    return `${environment.domainName}${environment.getDateWiseAvailabilityURL}`;
  }

  public static get getStaffListURL(): string {
    return `${environment.domainName}${environment.getStaffListURL}`;
  }

  public static get getStaffListForSectorURL(): string {
    return `${environment.domainName}${environment.getStaffListForSectorURL}`;
  }
  public static get getSPStaffListForSectorURL(): string {
    return `${environment.domainName}${environment.getSPStaffListForSectorURL}`;
  }
  public static get activateOrDeactivateCareGiverURL(): string {
    return `${environment.domainName}${environment.activateOrDeactivateCareGiverURL}`;
  }
  public static get activateOrDeactivateServiceProviderURL(): string {
    return `${environment.domainName}${environment.activateOrDeactivateServiceProviderURL}`;
  }
  public static get getAppointmentDetailsURL(): string {
    return `${environment.domainName}${environment.getAppointmentDetailsURL}`;
  }

  public static get getLabAppointmentDetailsURL(): string {
    return `${environment.domainName}${environment.getLabAppointmentDetailsURL}`;
  }

  public static get assignStaffForAppointmentURL(): string {
    return `${environment.domainName}${environment.assignStaffForAppointmentURL}`;
  }
  public static get assignCareGiverForLabAppointmentURL(): string {
    return `${environment.domainName}${environment.assignCareGiverForLabAppointmentURL}`;
  }

  public static get getSectorListURL(): string {
    return `${environment.domainName}${environment.getSectorListURL}`;
  }
  public static get getservicesectorsforlabURL(): string {
    return `${environment.domainName}${environment.getservicesectorsforlabURL}`;
  }


  public static get postIndividualTestCategoryURL(): string {
    return `${environment.domainName}${environment.postIndividualTestCategoryURL}`;
  }
  public static get postServicePackageCategoryURL(): string {
    return `${environment.domainName}${environment.postServicePackageCategoryURL}`;
  }
  public static get postIndividualTestURL(): string {
    return `${environment.domainName}${environment.postIndividualTestURL}`;
  }
  public static get postServicePackageURL(): string {
    return `${environment.domainName}${environment.postServicePackageURL}`;
  }
  public static get postCommonServiceURL(): string {
    return `${environment.domainName}${environment.postCommonServiceURL}`;
  }
  public static get getIndividualTestCategoriesURL(): string {
    return `${environment.domainName}${environment.getIndividualTestCategoriesURL}`;
  }
  public static get getServicePackageCategoriesURL(): string {
    return `${environment.domainName}${environment.getServicePackageCategoriesURL}`;
  }
  public static get getSPServicePackageCategoriesURL(): string {
    return `${environment.domainName}${environment.getSPServicePackageCategoriesURL}`;
  }
  public static get getLabEligibleLabPackagesURL(): string {
    return `${environment.domainName}${environment.getLabEligibleLabPackagesURL}`;
  }
  public static get getIndividualLabTestURL(): string {
    return `${environment.domainName}${environment.getIndividualLabTestURL}`;
  }
  public static get getLabIndividualLabTestURL(): string {
    return `${environment.domainName}${environment.getLabIndividualLabTestURL}`;
  }
  public static get getServicePackagesURL(): string {
    return `${environment.domainName}${environment.getServicePackagesURL}`;
  }
  public static get getProviderLabPackagesURL(): string {
    return `${environment.domainName}${environment.getProviderLabPackagesURL}`;
  }
  public static get getCommonServicesURL(): string {
    return `${environment.domainName}${environment.getCommonServicesURL}`;
  }

  public static get postNewCareProviderStaffURL(): string {
    return `${environment.domainName}${environment.postNewCareProviderStaffURL}`;
  }
 
  public static get postaddDoctorAvailabilityURL(): string {
    return `${environment.domainName}${environment.postaddDoctorAvailabilityURL}`;
  }
  public static get createNewServiceProviderStaffURL(): string {
    return `${environment.domainName}${environment.createNewServiceProviderStaffURL}`;
  }

  public static get postNewServiceSectorURL(): string {
    return `${environment.domainName}${environment.postNewServiceSectorURL}`;
  }

 
  public static get updateAppointmentStatusURL(): string {
    return `${environment.domainName}${environment.updateAppointmentStatusURL}`;
  }

  public static get getAdminDashboardURL(): string {
    return `${environment.domainName}${environment.getAdminDashboardURL}`;
  }

  public static get updateServiceSectorURL(): string {
    return `${environment.domainName}${environment.updateServiceSectorURL}`;
  }

  public static get updateLabPackageCategoryURL(): string {
    return `${environment.domainName}${environment.updateLabPackageCategoryURL}`;
  }

  public static get updateLabTestCatetoryURL(): string {
    return `${environment.domainName}${environment.updateLabTestCatetoryURL}`;
  }

  public static get updateLabPackageURL(): string {
    return `${environment.domainName}${environment.updateLabPackageURL}`;
  }

  public static get updateIndividualLabTestURL(): string {
    return `${environment.domainName}${environment.updateIndividualLabTestURL}`;
  }

  public static get updateCommonServiceURL(): string {
    return `${environment.domainName}${environment.updateCommonServiceURL}`;
  }

  public static get fileUploadURL(): string {
    return `${environment.domainName}${environment.fileUploadURL}`;
  }

  public static get registerNewPatientURL(): string {
    return `${environment.domainName}${environment.registerNewPatientURL}`;
  }

  public static get getPatientDetailsURL(): string {
    return `${environment.domainName}${environment.getPatientDetailsURL}`;
  }

  public static get searchPatientURL(): string {
    return `${environment.domainName}${environment.searchPatientURL}`;
  }

  public static get searchSPPatientURL(): string {
    return `${environment.domainName}${environment.searchSPPatientURL}`;
  }

  public static get getServiceListURL(): string {
    return `${environment.domainName}${environment.getServiceListURL}`;
  }

  public static get getPatientSourcesURL(): string {
    return `${environment.domainName}${environment.getPatientSourcesURL}`;
  }

  public static get postNewPatientSourceURL(): string {
    return `${environment.domainName}${environment.postNewPatientSourceURL}`;
  }

  public static get getQuoteForServiceListURL(): string {
    return `${environment.domainName}${environment.getQuoteForServiceListURL}`;
  }

  public static get getQuoteForServiceDSAdminURL(): string {
    return `${environment.domainName}${environment.getQuoteForServiceDSAdminURL}`;
  }
  public static get getQuoteForServiceDSLabAdminURL(): string {
    return `${environment.domainName}${environment.getQuoteForServiceDSLabAdminURL}`;
  }
  public static get confirmAppointmentBookingURL(): string {
    return `${environment.domainName}${environment.confirmAppointmentBookingURL}`;
  }
  public static get confirmAppointmentLabAdminURL(): string {
    return `${environment.domainName}${environment.confirmAppointmentLabAdminURL}`;
  }

  public static get confirmCareGiverBookingAdminURL(): string {
    return `${environment.domainName}${environment.confirmCareGiverBookingAdminURL}`;
  }
  public static get confirmCareGiverBookingLabAdminURL(): string {
    return `${environment.domainName}${environment.confirmCareGiverBookingLabAdminURL}`;
  }


  public static get postOtherServiceCategoryURL(): string {
    return `${environment.domainName}${environment.postOtherServiceCategoryURL}`;
  }

  public static get getOtherServiceCategoriesURL(): string {
    return `${environment.domainName}${environment.getOtherServiceCategoriesURL}`;
  }
  
  public static get getEmployeerListURL(): string {
    return `${environment.domainName}${environment.getEmployeerListURL}`;
  }

  public static get getCorpServicePackagesURL(): string {
    return `${environment.domainName}${environment.getCorpServicePackagesURL}`;
  }  

  public static get createNewEmployeerURL(): string {
    return `${environment.domainName}${environment.createNewEmployeerURL}`;
  }

  public static get createCorpServicePackageURL(): string {
    return `${environment.domainName}${environment.createCorpServicePackageURL}`;
  }

  public static get getOpenCorpAppointmentsURL(): string {
    return `${environment.domainName}${environment.getOpenCorpAppointmentsURL}`;
  }

  public static get getCorpAppointmentHistoryURL(): string {
    return `${environment.domainName}${environment.getCorpAppointmentHistoryURL}`;
  }

  public static get getCorpAppointmentDetailsURL(): string {
    return `${environment.domainName}${environment.getCorpAppointmentDetailsURL}`;
  }

  public static get assignCareGiverForCorpAppointmentURL(): string {
    return `${environment.domainName}${environment.assignCareGiverForCorpAppointmentURL}`;
  }
  
  public static get cancelAppointmentURL(): string {
    return `${environment.domainName}${environment.cancelAppointmentURL}`;
  }

  public static get getServiceProviderListURL(): string {
    return `${environment.domainName}${environment.getServiceProviderListURL}`;
  }
  public static get getSPDashboardDetailAdminURL(): string {
    return `${environment.domainName}${environment.getSPDashboardDetailAdminURL}`;
  }

  public static get postNewServiceProviderURL(): string {
    return `${environment.domainName}${environment.postNewServiceProviderURL}`;
  }

  public static get addOrUpdatePromoURL(): string {
    return `${environment.domainName}${environment.addOrUpdatePromoURL}`;
  }

  public static get addOrUpdateSPPromoURL(): string {
    return `${environment.domainName}${environment.addOrUpdateSPPromoURL}`;
  }   

  public static get getPromoListURL(): string {
    return `${environment.domainName}${environment.getPromoListURL}`;
  }

  public static get getSPPromoList(): string {
    return `${environment.domainName}${environment.getSPPromoList}`;
  }


  public static get getPromoUsageURL(): string {
    return `${environment.domainName}${environment.getPromoUsageURL}`;
  }

  public static get updatePromoStatusURL(): string {
    return `${environment.domainName}${environment.updatePromoStatusURL}`;
  }

  public static get updateSPPromoStatus(): string {
    return `${environment.domainName}${environment.updateSPPromoStatus}`;
  }  

  public static get getAppointmentsReportURL(): string {
    return `${environment.domainName}${environment.getAppointmentsReportURL}`;
  }

  public static get getServiceProviderDashboardURL(): string {
    return `${environment.domainName}${environment.getServiceProviderDashboardURL}`;
  }

  public static get getServiceProviderStaffListURL(): string {
    return `${environment.domainName}${environment.getServiceProviderStaffListURL}`;
  }

  public static get optInOptOutServiceURL(): string {
    return `${environment.domainName}${environment.optInOptOutServiceURL}`;
  }

  public static get getProviderLabTestsURL(): string {
    return `${environment.domainName}${environment.getProviderLabTestsURL}`;
  }
  
  public static get getProviderOtherServicesURL(): string {
    return `${environment.domainName}${environment.getProviderOtherServicesURL}`;
  }

  public static get getServiceProviderSettingsURL(): string {
    return `${environment.domainName}${environment.getServiceProviderSettingsURL}`;
  }

  public static get updateServiceProviderSettingsURL(): string {
    return `${environment.domainName}${environment.updateServiceProviderSettingsURL}`;
  }

  public static get updateServiceRequestStatusURL(): string {
    return `${environment.domainName}${environment.updateServiceRequestStatusURL}`;
  }
  public static get updateSPAppointmentStatusURL(): string {
    return `${environment.domainName}${environment.updateSPAppointmentStatusURL}`;
  }

  public static get addOrUpdateSpecializationCategoryURL(): string {
    return `${environment.domainName}${environment.addOrUpdateSpecializationCategoryURL}`;
  }

  public static get addOrUpdateSpecialityURL(): string {
    return `${environment.domainName}${environment.addOrUpdateSpecialityURL}`;
  }  

  public static get getSpecializationCategoryListURL(): string {
    return `${environment.domainName}${environment.getSpecializationCategoryListURL}`;
  }    
  

  public static get getSpecialityListURL(): string {
    return `${environment.domainName}${environment.getSpecialityListURL}`;
  }    

  public static get getSPSpecialityListURL(): string {
    return `${environment.domainName}${environment.getSPSpecialityListURL}`;
  }      

  public static get deleteLabResultFileURL(): string {
    return `${environment.domainName}${environment.deleteLabResultFileURL}`;
  }    

  public static get rescheduleAppointmentURL(): string {
    return `${environment.domainName}${environment.rescheduleAppointmentURL}`;
  }  

  public static get getCareGiverAvailabilityAdminURL(): string {
    return `${environment.domainName}${environment.getCareGiverAvailabilityAdminURL}`;
  } 

  public static get getNewlyAddedCareProviderStaffListURL(): string {
    return `${environment.domainName}${environment.getNewlyAddedCareProviderStaffListURL}`;
  } 

  public static get approveSPCareGiverURL(): string {
    return `${environment.domainName}${environment.approveSPCareGiverURL}`;
  } 

  public static get getSPAddedPromotionsListURL(): string {
    return `${environment.domainName}${environment.getSPAddedPromotionsListURL}`;
  } 

  public static get approveSPPromotionsURL(): string {
    return `${environment.domainName}${environment.approveSPPromotionsURL}`;
  } 

  public static get getCareGiverDetailsAdminURL(): string {
    return `${environment.domainName}${environment.getCareGiverDetailsAdminURL}`;
  } 
  public static get getSPCareGiverDetailsAdminURL(): string {
    return `${environment.domainName}${environment.getSPCareGiverDetailsAdminURL}`;
  } 
  public static get getCareGiverWorkingHoursURL(): string {
    return `${environment.domainName}${environment.getCareGiverWorkingHoursURL}`;
  }

  public static get updateAppointmentStatusToNoShowURL(): string {
    return `${environment.domainName}${environment.updateAppointmentStatusToNoShowURL}`;
  } 

  public static get getServiceProviderDetailsAdminURL(): string {
    return `${environment.domainName}${environment.getServiceProviderDetailsAdminURL}`;
  } 

  public static get getSPSpecializationCategoryListURL(): string {
    return `${environment.domainName}${environment.getSPSpecializationCategoryListURL}`;
  }   

  public static get updateServiceRequestStatusLabURL(): string {
    return `${environment.domainName}${environment.updateServiceRequestStatusLabURL}`;
  }   

  public static get getcategoriesURL(): string {
    return `${environment.domainName}${environment.getcategoriesURL}`;
  }  

  public static get categoryURL(): string {
    return `${environment.domainName}${environment.categoryURL}`;
  }  

  public static get activeInactiveOrApproveRejectURL(): string {
    return `${environment.domainName}${environment.activeInactiveOrApproveRejectURL}`;
  }  
  
  public static get getAllProductListURL(): string {
    return `${environment.domainName}${environment.getAllProductListURL}`;
  }  
  public static get addproductURL(): string {
    return `${environment.domainName}${environment.addproductURL}`;
  }  
  public static get userURL(): string {
    return `${environment.domainName}${environment.userURL}`;
  }  
  public static get usersListURL(): string {
    return `${environment.domainName}${environment.usersListURL}`;
  }
  public static get getAppointmentListURL(): string {
    return `${environment.domainName}${environment.getAppointmentListURL}`;
  }
  public static get getAppointmentDetailURL(): string {
    return `${environment.domainName}${environment.getAppointmentDetailURL}`;
  }
  public static get doctorAnswerForQuestionsURL(): string {
    return `${environment.domainName}${environment.doctorAnswerForQuestionsURL}`;
  }
  public static get prescriptionNotesURL(): string {
    return `${environment.domainName}${environment.prescriptionNotesURL}`;
  }
  public static get storeFileUploadURL(): string {
    return `${environment.domainName}${environment.storeFileUploadURL}`;
  }

  public static get availableSlotsURL(): string {
    return `${environment.domainName}${environment.availableSlotsURL}`;
  }

  public static get getDependantsURL(): string {
    return `${environment.domainName}${environment.getDependantsURL}`;
  }

  public static get quoteURL(): string {
    return `${environment.domainName}${environment.quoteURL}`;
  }

  public static get orderURL(): string {
    return `${environment.domainName}${environment.orderURL}`;
  }

  public static get AllInstantCaregiverListURL(): string {
    return `${environment.domainName}${environment.AllInstantCaregiverListURL}`;
  }

  public static get InstantCaregiverListURL(): string {
    return `${environment.domainName}${environment.InstantCaregiverListURL}`;
  }

  public static get registerForUrgentConsultationURL(): string {
    return `${environment.domainName}${environment.registerForUrgentConsultationURL}`;
  }

  public static get AllUrgentCosnultationActiveAndInActiveListURL(): string {
    return `${environment.domainName}${environment.AllUrgentCosnultationActiveAndInActiveListURL}`;
  }

  public static get UrgentCosnultationActiveAndInActiveListURL(): string {
    return `${environment.domainName}${environment.UrgentCosnultationActiveAndInActiveListURL}`;
  }

  public static get articlecategoryURL(): string {
    return `${environment.domainName}${environment.articlecategoryURL}`;
  }
  public static get FileUploadURL(): string {
    return `${environment.domainName}${environment.FileUploadURL}`;
  }
  public static get articleCategoryURL(): string {
    return `${environment.domainName}${environment.articleCategoryURL}`;
  }
  public static get articleCatStatusURL(): string {
    return `${environment.domainName}${environment.articleCatStatusURL}`;
  }
  public static get articleAddURL(): string {
    return `${environment.domainName}${environment.articleAddURL}`;
  }
  public static get articleListURL(): string {
    return `${environment.domainName}${environment.articleListURL}`;
  }
  public static get articleStatusURL(): string {
    return `${environment.domainName}${environment.articleStatusURL}`;
  }
  public static get bookUrgentConsultationURL(): string {
    return `${environment.domainName}${environment.bookUrgentConsultationURL}`;
  }
  public static get confirmUrgentConsultationURL(): string {
    return `${environment.domainName}${environment.confirmUrgentConsultationURL}`;
  }
  public static get promoImageUploadURL(): string {
    return `${environment.domainName}${environment.promoImageUploadURL}`;
  }
  public static get accountStatusURL(): string {
    return `${environment.domainName}${environment.accountStatusURL}`;
  }
  public static get getAppointmentHistortListURL(): string {
    return `${environment.domainName}${environment.getAppointmentHistortListURL}`;
  }
  public static get reportsURL(): string {
    return `${environment.domainName}${environment.reportsURL}`;
  }

  public static get getOrderListURL(): string {
    return `${environment.domainName}${environment.getOrderListURL}`;
  }
  public static get getOrderDetailURL(): string {
    return `${environment.domainName}${environment.getOrderDetailURL}`;
  }
}
