import { Injectable ,EventEmitter,Output} from '@angular/core';
import { lang } from 'moment';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  @Output() aClickedEvent = new EventEmitter<string>();

AClicked(msg: string) {
  this.aClickedEvent.emit(msg);
}

  private _unsubscribeAll: Subject<any>;
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  public currentLanguageSubject: BehaviorSubject<any>;
  public currentLanguage: Observable<any>;

  public appLogoutSubject: BehaviorSubject<any>;
  public appLogout: Observable<any>;

  constructor(
   
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    if (this.currentUser == null) {
      this.currentUserSubject.next(null);
    }

    this.currentLanguageSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('appLanguage')));
    this.currentLanguage = this.currentLanguageSubject.asObservable();
    

    if (this.currentLanguage == null) {
      this.currentLanguageSubject.next(null);
    }

    this.appLogoutSubject = new BehaviorSubject<any>(null);
    this.appLogout = this.appLogoutSubject.asObservable();
  }


  storeUserAuthInfo(userDetails) {
    localStorage.setItem('currentUser', JSON.stringify(userDetails));
  }

  storeAppLanguage(languageSettings) {
    localStorage.setItem('appLanguage', JSON.stringify(languageSettings));
    console.log(languageSettings)
  }

  logout() {
    this.appLogoutSubject.next(true);
   
    this.currentUserSubject.next(null);
  }

}