// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },

  domainName: "https://blueberry.com.sa/blueberryapi/", //PROD URL
  // domainName: "http://localhost:3000/",
  // domainName: "https://albaitmedistore.com/services/", //PROD URL
  // domainName: "https://annztech.com/dsapi/",
  //domainName: "https://annztech.com/dsapiprod/",
  // domainName: "https://annztech.com/blueberryapi/",
  //domainName: "https://annztech.com/modadapi/",

  getServiceProviderAppointmentListURL: "getServiceProviderStaffAppointments",

   loginURL: "login",
  //loginURL: "serviceProviderAdminLogin",

  getStaffListURL: "getStaffList",
  getPatientListURL: "getPatientList",
  getSPPatientsListURL: "getSPPatientsList",
  getAvailableTimeListURL: "getAvailableTimeList",
  getDateWiseAvailabilityURL: "getDateWiseAvailability",
  getProviderAppointmentListURL: "getProviderAppointments",
  getAppointmentDetailsURL: "getProviderAppointmentDetails",
  getLabAppointmentDetailsURL: "getLabAppointmentDetails",
  getStaffListForSectorURL: "getStaffsForSector",
  getSPStaffListForSectorURL: "getSPStaffListForSector",
  activateOrDeactivateCareGiverURL: "activateOrDeactivateCareGiver",
  activateOrDeactivateServiceProviderURL: "activateOrDeactivateSPCareGiver",
  assignStaffForAppointmentURL: "assignStaffForAppointment",
  assignCareGiverForLabAppointmentURL:"assignCareGiverForLabAppointment",
  getSectorListURL: "getAdminServiceSectors",
  getservicesectorsforlabURL: "getservicesectorsforlab",

  postIndividualTestCategoryURL: "postIndividualTestCategory",
  postServicePackageCategoryURL: "postServicePackageCategory",
  // postOtherServiceCategoryURL: "addNewOtherServiceCategory",
  postOtherServiceCategoryURL: "postCommonServiceCategory",
  
  // getOtherServiceCategoriesURL: "getOtherServiceCategories",
  getOtherServiceCategoriesURL: "getCommonServiceCategories",
  postIndividualTestURL: "postIndividualTest",
  postServicePackageURL: "postServicePackage",
  postCommonServiceURL: "postCommonService",
  getIndividualTestCategoriesURL: "getIndividualTestCategories",
  getServicePackageCategoriesURL: "getServicePackageCategories",
  getSPServicePackageCategoriesURL: "getSPServicePackageCategories",
  getLabEligibleLabPackagesURL: "getLabEligibleLabPackages",
  getIndividualLabTestURL: "getIndividualLabTest",
  getLabIndividualLabTestURL: "getLabIndividualLabTest",
  getServicePackagesURL: "getServicePackages",
  getCommonServicesURL: "getCommonServices",
  postNewCareProviderStaffURL: "createCareProviderStaff",

 
  postNewServiceSectorURL: "postServiceSector",
  updateAppointmentStatusURL: "updateAppointmentStatus",
  updateServiceSectorURL: "updateServiceSector",
  updateLabPackageCategoryURL: "updateLabPackageCategory",
  updateLabTestCatetoryURL: "updateLabTestCatetory",
  updateLabPackageURL: "updateLabPackage",
  updateIndividualLabTestURL: "updateIndividualLabTest",
  updateCommonServiceURL: "updateCommonService",

  registerNewPatientURL: "registerNewPatient",
  getPatientDetailsURL: "getPatientDetailAdmin",
  searchPatientURL: "getPatientInfoAdmin",
  searchSPPatientURL: "getSPPatientInfoAdmin",
  getServiceListURL: "getServiceList",
  
  getPatientSourcesURL: "getPaitentSources",
  postNewPatientSourceURL: "postPatientSource",
  getQuoteForServiceListURL: "getQuoteForServiceList",
  confirmAppointmentBookingURL: "confirmAppointmentV2",
  confirmAppointmentLabAdminURL: "confirmAppointmentLabAdmin",

  getQuoteForServiceDSAdminURL: "getQuoteForServiceDSAdmin",
  getQuoteForServiceDSLabAdminURL: "getQuoteForServiceDSLabAdmin",
  confirmCareGiverBookingAdminURL: "confirmCareGiverBookingAdmin",
  confirmCareGiverBookingLabAdminURL: "confirmCareGiverBookingLabAdmin",

  //EMPLOYEE CHECK
  getEmployeerListURL: "getEmployeerLIst",
  getCorpServicePackagesURL: "getCorpServicePackages",
  createNewEmployeerURL: "createNewEmployeer",
  createCorpServicePackageURL: "addBusinessPackage",
  getOpenCorpAppointmentsURL: "getBusinessAppointmentList",
  getCorpAppointmentHistoryURL: "getBusinessAppointmentList",
  getCorpAppointmentDetailsURL: "getBusinessAppointmentDetails",
  assignCareGiverForCorpAppointmentURL: "scheduleBusinessAppointment",
  cancelAppointmentURL: "cancelAppointment",

  // CLINICS
  getServiceProviderListURL: "getServiceProviderList",
  postNewServiceProviderURL: "postNewServiceProvider",
  getSPPromoList: "getSPPromoList",
  addOrUpdateSPPromoURL: "addOrUpdateSPPromo",
 
  getPromoUsageURL: "getPromoUsageHistory",
  updateSPPromoStatus: "updateSPPromoStatus",

  //REPORTS
  getAppointmentsReportURL: "getAppointmentsReport",
  //CLINIC DASHBOARD DETAIL ADMIN
  getSPDashboardDetailAdminURL: "getSPDashboardDetailAdmin",
  // CLINIC STAFF
  getServiceProviderDashboardURL: "getServiceProviderDashboard",
  createNewServiceProviderStaffURL: "createNewServiceProviderStaff",
  getServiceProviderStaffListURL: "getServiceProviderStaffList",

  getLabAppointmentListURL: "getLabAppointmentList",
  getProviderLabPackagesURL: "getLabEligibleLabPackages",
  optInOptOutServiceURL: "optInOptOutService",
  getProviderLabTestsURL: "getLabEligibleLabTests",
  getProviderOtherServicesURL: "getLabEligibleCommonServices",
  getServiceProviderSettingsURL: "getServiceProviderSettings",
  updateServiceProviderSettingsURL: "updateServiceProviderSettings",
  updateServiceRequestStatusURL: "orderStatus",
  updateSPAppointmentStatusURL: "updateSPAppointmentStatus",

  //SPECIALIZATION CATEGORY
  addOrUpdateSpecializationCategoryURL: "addOrUpdateSpecializationCategory",
  addOrUpdateSpecialityURL: "addOrUpdateSpeciality",
  getSpecializationCategoryListURL: "getSpecializationCategoryList",
  getSPSpecializationCategoryListURL: "getSPSpecializationCategoryList",
  getSpecialityListURL: "getSpecialityList",
  getSPSpecialityListURL: "getSPSpecialityList",

  deleteLabResultFileURL: "deleteLabResultFile",
  rescheduleAppointmentURL: "rescheduleAppointment",

  getCareGiverAvailabilityAdminURL: "getCareGiverAvailabilityAdmin",

  getNewlyAddedCareProviderStaffListURL: "getNewlyAddedCareProviderStaffList",
  approveSPCareGiverURL: "approveSPCareGiver",
  getSPAddedPromotionsListURL: "getSPAddedPromotionsList",
  approveSPPromotionsURL: "approveSPPromotions",
  getCareGiverDetailsAdminURL: "getCareGiverDetailsAdmin",
  getSPCareGiverDetailsAdminURL:"getSPCareGiverDetailsAdmin",
  getCareGiverWorkingHoursURL: "getCareGiverWorkingHours",
  updateAppointmentStatusToNoShowURL: "updateAppointmentStatusToNoShow",
  getServiceProviderDetailsAdminURL: "getServiceProviderDetailsAdmin",

  // getSPSpecializationCategoryListURL: "getSPSpecializationCategoryList",

  updateServiceRequestStatusLabURL: "updateSPAppointmentStatus",

  fileUploadURL: "fileUpload",

  //Dashboard Details
  getAdminDashboardURL: "getAdminDashboard",

  //Product Category
  getcategoriesURL: "categories",
  categoryURL: "category",
  activeInactiveOrApproveRejectURL: "activeInactiveOrApproveReject",

  //Product
  getAllProductListURL: "sProducts",
  addproductURL: "product",

  //Vendor
  userURL: "user",
  usersListURL: "usersAdmin",

  //Image Upload on Category,Product and Vendor
  storeFileUploadURL: "storeFileUpload",


  //Appointment
  getAppointmentListURL: "orders",
  getAppointmentDetailURL: "order?orderId=",
  doctorAnswerForQuestionsURL: "doctorAnswerForQuestions?appointmentId=",
  prescriptionNotesURL: "appointmentNote?appointmentId=",
  //Appointment History
  getAppointmentHistortListURL: "allOrders",

  //Slots
  availableSlotsURL: "availableSlots",

  //Dependent for Patient
  getDependantsURL: "dependent", 

  //Quote
  quoteURL: "quote",

  //Caregiver Status Change
  accountStatusURL: "accountStatus",

  //Appointment Book
  orderURL: "order",

  //Promotion 
  getPromoListURL: "getPromoList",
  addOrUpdatePromoURL: "addOrUpdatePromo",
  updatePromoStatusURL: "updatePromoStatus",
  promoImageUploadURL: "promoImageUpload",

  //Doctor Availablity Add
  postaddDoctorAvailabilityURL: "availability",

  AllInstantCaregiverListURL: "doctorsAvailableForUrgentConsultation",

  InstantCaregiverListURL: "doctorsAvailableForUrgentConsultation?date=",
  registerForUrgentConsultationURL: "registerForUrgentConsultation",

  //Meeting Room
  AllUrgentCosnultationActiveAndInActiveListURL: "urgentCosnultationActiveAndInActiveList",
  UrgentCosnultationActiveAndInActiveListURL: "urgentCosnultationActiveAndInActiveList?date=",

  //Article Category
  articlecategoryURL: "articleCategory",
  FileUploadURL: "file/upload/article",
  articleCategoryURL: "articleCategory",
  articleCatStatusURL: "articleCatStatus",

  //Article
  articleAddURL: "article",
  articleListURL: "articles",
  articleStatusURL: "articleStatus",

  //UrgentConsultation Book
  bookUrgentConsultationURL:"bookUrgentConsultation",
  confirmUrgentConsultationURL: "confirmUrgentConsultation",

  //Reports
  reportsURL: "reports",

  //Orders
  getOrderListURL: "orderedProducts",
  getOrderDetailURL: "orderedProduct?orderedProductId=",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
